<template>
  <div class="">
    <div class="tj-box">
      <div class="item">
        <div class="ico-box color1"><div class="ico"><i class="i el-icon-coin"></i></div></div>
        <div>
          <div class="amount">{{ tj.order_amount }}</div>
          <div class="tips" @click="goUrl(1)">订单总金额 <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color2"><div class="ico"><i class="i el-icon-s-data"></i></div></div>
        <div>
          <div class="amount">{{ tj.order_sum }}</div>
          <div class="tips" @click="goUrl(2)">订单数 <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color3"><div class="ico"><i class="i el-icon-s-finance"></i></div></div>
        <div>
          <div class="amount">{{ tj.trade_amount }}</div>
          <div class="tips">交易总金额</div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color4"><div class="ico"><i class="i el-icon-s-finance"></i></div></div>
        <div>
          <div class="amount">{{ tj.refund_amount }}</div>
          <div class="tips">退款总金额</div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color5"><div class="ico"><i class="i el-icon-user"></i></div></div>
        <div>
          <div class="amount">{{ tj.member_sum }}</div>
          <div class="tips" @click="goUrl(3)">用户数 <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
    </div>
    <div class="main-box mg-tp-10">
      <el-form class="form-inline" :inline="true">
        <el-form-item label="">
          <el-date-picker
              size="small"
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="char_title mg-tp-30">订单统计</div>
      <div class="echart" id="mychart1" :style="myChartStyle"></div>
      <div class="char_title mg-tp-30">交易统计</div>
      <div class="echart" id="mychart2" :style="myChartStyle"></div>
      <div class="char_title mg-tp-30">退款统计</div>
      <div class="echart" id="mychart3" :style="myChartStyle"></div>
      <div class="char_title mg-tp-30">充值统计</div>
      <div class="echart" id="mychart4" :style="myChartStyle"></div>
      <div class="char_title mg-tp-30">商品销售TOP5</div>
      <el-table
          :data="dataInfo.top5"
          style="width: 100%;">
        <el-table-column
            prop="title"
            label="商品名称">
        </el-table-column>
        <el-table-column
            prop="sum"
            label="订购数量（件）"
            width="180">
        </el-table-column>
        <el-table-column
            prop="pay_price"
            label="订购金额（元）"
            width="180">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>

import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
  components: {
  },
  data() {
    return {
      tj: {
        order_amount: 0,
        order_sum: 0,
        trade_amount: 0,
        refund_amount: 0,
        member_sum: 0
      },
      dataInfo: false,
      dateRange: ['',''],
      myChartStyle: { float: "left", width: "100%", height: "300px" },
      myChart1: false,
      myChart2: false,
      myChart3: false,
      myChart4: false,
      pickerOptions: {
        shortcuts: [{
          text: '当日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当周',
          onClick(picker) {
            var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
            var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
            if(new Date().getDay()==0) { //周日前推6天
              start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
              end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
            }
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当月',
          onClick(picker) {
            const start = new Date();
            start.setDate(1)
            const end = new Date(start.getFullYear(),start.getMonth()+1,0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当季度',
          onClick(picker) {
            const today = new Date();
            const year = today.getFullYear();
            const quarter = Math.floor((today.getMonth() + 3) / 3);
            const start = new Date(year, (quarter - 1) * 3, 1);
            const end = new Date(year, quarter * 3, 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  created() {
    var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
    var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
    if(new Date().getDay()==0) { //周日前推6天
      start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
      end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
    }
    this.dateRange[0] = start
    this.dateRange[1] = end
    this.getStatic()
    this.handleSearch()
  },
  mounted() {
  },
  computed: {},
  methods: {
    getStatic() {
      this.$api.order.orderTradeTjIndex({}, res=> {
        this.tj = res.data
      })
    },
    goUrl(type) {
      if(type==1 || type==2) {
        window.open('/order/index')
      } else if(type==3) {
        window.open('/member/index')
      }
    },
    handleSearch() {
      var params = {
        start_time: this.dateRange[0],
        end_time: this.dateRange[1],
      }
      this.$api.order.orderTradeTjTime(params, res => {
        if(res.errcode==0) {
          this.dataInfo = res.data
          this.initEcharts1();
          this.initEcharts2();
          this.initEcharts3();
          this.initEcharts4();
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getOption(data1,data2,title1,title2) {
      var options = {
        tooltip: {},
        legend: {
          data: [title1, title2]
        },
        xAxis: {
          data: this.dataInfo.x
        },
        yAxis: {},
        series: [
          {
            name: title1,
            type: 'line',
            smooth: true,
            data: data1
          },
          {
            name: title2,
            type: 'line',
            smooth: true,
            data: data2
          }
        ]
      };
      return options
    },
    initEcharts1() {
      var options = this.getOption(this.dataInfo.data11,this.dataInfo.data12,'订单金额','订单数')
      this.myChart1 = echarts.init(document.getElementById("mychart1"));
      this.myChart1.setOption(options);
      window.addEventListener("resize", () => {
        this.myChart1.resize();
      });
    },
    initEcharts2() {
      var options = this.getOption(this.dataInfo.data21,this.dataInfo.data22,'交易金额','交易数量')
      this.myChart2 = echarts.init(document.getElementById("mychart2"));
      this.myChart2.setOption(options);
      window.addEventListener("resize", () => {
        this.myChart2.resize();
      });
    },
    initEcharts3() {
      var options = this.getOption(this.dataInfo.data31,this.dataInfo.data32,'退款金额','退款数量')
      this.myChart3 = echarts.init(document.getElementById("mychart3"));
      this.myChart3.setOption(options);
      window.addEventListener("resize", () => {
        this.myChart3.resize();
      });
    },
    initEcharts4() {
      var options = this.getOption(this.dataInfo.data41,this.dataInfo.data42,'充值金额','充值数量')
      this.myChart4 = echarts.init(document.getElementById("mychart4"));
      this.myChart4.setOption(options);
      window.addEventListener("resize", () => {
        this.myChart4.resize();
      });
    },
  }
};
</script>
<style scoped>
</style>
